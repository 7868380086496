// eslint-disable-next-line import/named
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#68985C',
    secondary: '#B19D59',
    error: '#CA574B',
    warning: '#DBAE4C',
    black: '#262525',
    white: '#FBFBFB',
    purewhite: '#FFFFFF',
    light: '#FCF4E1',
    light_dimmed: '#F9EAC7',
    gray: '#7d7d7d',
    gray_dark: '#5c5c5c',
    label_gray: '#93918a',
    lightgray: '#D0D0D0',
    text: '#1d1d1d',
    placeholder_bg: '#efefef'
  },
  spacing: {
    s: '4px',
    m: '8px',
    l: '16px',
    xl: '32px'
  },
  fontSizes: {
    s: '10px',
    m: '14px',
    l: '22px',
    xl: '32px'
  }
};

export type OF_Theme = typeof theme;
export default theme;
