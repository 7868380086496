import React, { FunctionComponent } from 'react';

import { StyledCodeBlock } from './Styles';

const CodeBlock: FunctionComponent = ({ children }) => {
  return (
    <StyledCodeBlock>
      <code>{children}</code>
    </StyledCodeBlock>
  );
};

export default CodeBlock;
