import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }): string => theme.colors.light};
    font-family: 'Merriweather Sans', sans-serif;
    line-height: 1.4;
  }

  body,
  #root {
    min-height: 100vh;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
`;

export default GlobalStyles;
