import styled from 'styled-components';

export const StyledButton = styled.a`
  background-color: ${({ theme }): string => theme.colors.primary};
  color: ${({ theme }): string => theme.colors.white};
  text-align: center;
  min-width: 250px;
  font-size: ${({ theme }): string => theme.fontSizes.m};
  padding: 1rem 1.25rem;
  outline: none;
  border: none;
  text-decoration: none;
  font-weight: bold;
  border-radius: 200px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
