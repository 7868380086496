import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AppNavbar } from 'components/modules/navbar/AppNavbar';

import { ErrorScreen } from 'screens/errors';
import { Dispatcher } from 'screens/integrationDispatcher';
import { PayWithPayPal } from 'screens/paypal';
import { PaymentSuccessScreen } from 'screens/success';

import { Routes } from 'utils/enums/routes';

const AppRouter: FunctionComponent = () => {
  return (
    <Router>
      <AppNavbar />
      <Switch>
        {/**
         * Handles paypal payment provider integration
         */}
        <Route path={Routes.PAYPAL} exact>
          <PayWithPayPal />
        </Route>
        {/**
         * Handles redirect after successfull payment
         */}
        <Route path={Routes.SUCCESS} exact>
          <PaymentSuccessScreen />
        </Route>
        {/**
         * Handles errors
         */}
        <Route path={Routes.ERROR} exact>
          <ErrorScreen />
        </Route>
        {/**
         * By default, user is expected to land on a root page
         * with a bunch of query parameters. This "landing" will be handled
         * by integration dispatcher, which will then, based on the provided
         * query parameters, re-route user to the correct part of the app
         */}
        <Route path={Routes.ROOT} exact>
          <Dispatcher />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
