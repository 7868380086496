import React, { FunctionComponent } from 'react';

import { HeadingProps } from './interfaces';

import { StyledHeading } from './Styles';

const Heading: FunctionComponent<HeadingProps> = ({ children, tag = 'h2' }) => {
  return <StyledHeading as={tag}>{children}</StyledHeading>;
};

export default Heading;
