import React, { FunctionComponent } from 'react';

import errorImage from 'assets/images/error-image.svg';

import { Heading } from 'components/atoms/Heading';
import { Image } from 'components/atoms/Image';
import { ScreenContainer } from 'components/atoms/ScreenContainer';
import { Text } from 'components/atoms/Text';
import { CodeBlock } from 'components/ui/CodeBlock';

import useQuery from 'hooks/useQuery';
import useRouterState from 'hooks/useRouterState';

import { logExceptionWithDataToSentry } from 'utils/helpers/sentry';

import { StyledImageContainer } from './Styles';

const ErrorScreen: FunctionComponent = () => {
  const queryParams = useQuery();
  const routerState = useRouterState<{ error: string; params: Record<string, unknown> }>();

  React.useEffect(() => {
    if (routerState.error) {
      logExceptionWithDataToSentry(new Error(routerState.error), routerState.params);
    }
  }, [queryParams, routerState]);

  return (
    <ScreenContainer>
      <StyledImageContainer>
        <Image src={errorImage} alt="Error" />
      </StyledImageContainer>
      <Heading>Error...</Heading>
      <Text>The following error has occured:</Text>
      <CodeBlock>{routerState?.error}</CodeBlock>
    </ScreenContainer>
  );
};

export default ErrorScreen;
