import React from 'react';

import { Heading, ScreenContainer, Text } from 'components/atoms';
import { Button, LoadingIndicator } from 'components/ui';

import useRouterState from 'hooks/useRouterState';

import { logExceptionToSentry } from 'utils/helpers/sentry';

/**
 * Component rendering PaymentSuccess screen. It tries to redirect the user to the provided redirectUri.
 * In case that the redirect does not work OOTB, it displays a button for manual redirect trigger.
 *
 * @returns {React.FC}
 */
const PaymentSuccessScreen: React.FC = () => {
  const [buttonVisible, setButtonVisible] = React.useState<boolean>(false);
  const { redirectUri } = useRouterState<{ redirectUri: string }>();

  const redirect = React.useCallback(() => {
    try {
      setTimeout(() => {
        window.location.assign(redirectUri);
      }, 1000);
    } catch (e) {
      logExceptionToSentry(e);
    }
  }, [redirectUri]);

  React.useEffect(() => {
    redirect();
  }, [redirect]);

  React.useEffect(() => {
    setTimeout(() => {
      setButtonVisible(true);
    }, 2000);
  }, []);

  return (
    <>
      {buttonVisible ? (
        <ScreenContainer>
          <Heading tag="h1">Niste bili preusmerjeni?</Heading>
          <Text>Kliknite na spodnjo povezavo</Text>
          <Button href={redirectUri} style={{ marginTop: 48 }}>
            Nazaj v aplikacijo
          </Button>
        </ScreenContainer>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

export default PaymentSuccessScreen;
