import React, { FunctionComponent } from 'react';

import { Logo } from 'components/ui/Logo';

import { StyledAppNavbarLogo } from './Styles';

const AppNavbarLogo: FunctionComponent = () => {
  return (
    <StyledAppNavbarLogo>
      <Logo />
    </StyledAppNavbarLogo>
  );
};

export default AppNavbarLogo;
