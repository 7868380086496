import React, { FunctionComponent } from 'react';

import { ButtonProps } from './interfaces';
import { StyledButton } from './Styles';

const Button: FunctionComponent<ButtonProps> = ({ children, href, ...rest }) => {
  return (
    <StyledButton href={href} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
