import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingIndicator } from 'components/ui/LoadingIndicator';
import useQuery from 'hooks/useQuery';

import {
  getAmountQueryParam,
  getOsQueryParam,
  getProviderQueryParam,
  getQueryParamsConfig,
  getRedirectUriQueryParam
} from 'utils/helpers/queryParameters';

const Dispatcher: FunctionComponent = () => {
  const history = useHistory();
  const queryParams = useQuery();

  React.useEffect(() => {
    const amount = getAmountQueryParam(queryParams);
    const provider = getProviderQueryParam(queryParams);
    const redirectUri = getRedirectUriQueryParam(queryParams);
    const os = getOsQueryParam(queryParams);

    const { redirectPath, redirectState } = getQueryParamsConfig({ amount, provider, redirectUri, os });

    history.push(redirectPath, redirectState);
  }, [history, queryParams]);

  return <LoadingIndicator />;
};

export default Dispatcher;
