import { Providers } from 'utils/enums/providers';
import { Routes } from 'utils/enums/routes';
import { providerQueryParam } from 'utils/types/queryParams';

export const getRedirectPathByProvider = (provider: providerQueryParam): Routes => {
  if (provider === Providers.PAYPAL) {
    return Routes.PAYPAL;
  }

  return Routes.ERROR;
};
