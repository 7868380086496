import { validProviders } from 'utils/constants/providers';
import { providerQueryParam } from 'utils/types/queryParams';

import { isEqual } from './atoms/bools';

/**
 * Returns true if provider is valid provider
 * @function
 * @param {providerQueryParam} provider
 * @returns {boolean } boolean
 */
export const isValidProvider = (provider: providerQueryParam): boolean => {
  return Object.values(validProviders).some((validProvider) => isEqual(validProvider, provider));
};
