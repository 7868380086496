import { isFalsy } from '../atoms/bools';

export const areAllValuesTruthy = (values: any[]): boolean => {
  for (const value in values) {
    if (isFalsy(value)) {
      return false;
    }
  }

  return true;
};
