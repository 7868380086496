import { validOS } from 'utils/constants/os';
import { osQueryParam } from 'utils/types/queryParams';

import { isEqual } from './atoms/bools';
/**
 * Returns true of provided os is a valid os
 * @function
 * @param {osQueryParam} osToCheck
 * @returns {boolean} boolean
 */
export const isValidOs = (osToCheck: osQueryParam): boolean => {
  return Object.values(validOS).some((os) => isEqual(os, osToCheck));
};
