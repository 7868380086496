import {
  CreateOrderActions,
  OnApproveOrderActions,
  OnApproveOrderData,
  OnCancelledOrderData,
  PayPalButtons
} from '@paypal/react-paypal-js';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingIndicator } from 'components/ui';

import useRouterState from 'hooks/useRouterState';

import { Routes } from 'utils/enums/routes';
import { isValidOs } from 'utils/helpers/os';
import {
  createPayPalOrderData,
  getCancelRedirectUri,
  getErrorRedirectUri,
  getSuccessRedirectUri
} from 'utils/helpers/paypal';
import { logExceptionToSentry, logMessageWithDataToSentry } from 'utils/helpers/sentry';

import { IRouterState } from './interfaces';
import { StyledPayPalButtonsContainer } from './Styles';

const PayWithPayPal: FunctionComponent = () => {
  const { amount, os, redirectUri } = useRouterState<IRouterState>();
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);

  const data = createPayPalOrderData(amount);

  const createOrder = (_: any, actions: CreateOrderActions): Promise<string> => {
    return actions.order.create(data);
  };

  const handleRedirectOnFinishedPayPalAction = (actionRedirectUri: string): void => {
    if (isValidOs(os)) {
      history.push(Routes.SUCCESS, { redirectUri: actionRedirectUri });
    } else {
      throw new Error('Invalid OS provided.');
    }
  };

  const onOrderApprove = async (
    { orderID, payerID }: OnApproveOrderData,
    actions: OnApproveOrderActions
  ): Promise<void> => {
    // UI only, displays loading indicator so that the user knows
    // that something is happening
    setLoading(true);
    try {
      // Try to capture the newly created order
      const capturedOrderData = await actions.order.capture();

      // After the capture, order should have status of COMPLETED; anything else indicates that
      // something was not excuted right. If order status is indeed equal to COMPLETED, redirect user
      // with success response
      if (capturedOrderData.status === 'COMPLETED') {
        const successRedirect = getSuccessRedirectUri({ redirectUri, orderID, payerID });
        handleRedirectOnFinishedPayPalAction(successRedirect);
      } else {
        // In this case, when trying to capture the order, the response was not COMPLETED.
        // We want to redirect user with cancel response and we want to log this to Sentry in
        // case customer contacts us. Full data will be sent to Sentry, so the support has all
        // available info
        logMessageWithDataToSentry('Order capture not completed successfully', capturedOrderData);
        onCancelOrder({ orderID: capturedOrderData.id });
      }
    } catch (err) {
      // Some sort of critical error has occured while trying to capture the funds, we want
      // to redirect user with error response (error responses are already connected with Sentry,
      // no need for additional log here).
      onOrderError(err.message);
    }
  };

  const onCancelOrder = ({ orderID }: OnCancelledOrderData): void => {
    const cancelRedirect = getCancelRedirectUri({ redirectUri, orderID });
    handleRedirectOnFinishedPayPalAction(cancelRedirect);
  };

  const onOrderError = (message?: string): void => {
    /**
     * Although this is not "breaking" the app and can be interpreted
     * as "handled" behaviour, we still want an exception shown on Sentry
     * when this happens
     */
    logExceptionToSentry(new Error(message || 'Error on PayPal...'));

    const errorRedirect = getErrorRedirectUri({ redirectUri });
    handleRedirectOnFinishedPayPalAction(errorRedirect);
  };

  return (
    <StyledPayPalButtonsContainer>
      {loading && <LoadingIndicator />}
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onOrderApprove}
        onCancel={onCancelOrder}
        onError={onOrderError}
      />
    </StyledPayPalButtonsContainer>
  );
};

export default PayWithPayPal;
