import React, { FunctionComponent } from 'react';

import { TextProps } from './interfaces';

import { StyledText } from './Styles';

const Text: FunctionComponent<TextProps> = ({ children, tag = 'p' }) => {
  return <StyledText as={tag}>{children}</StyledText>;
};

export default Text;
