import React, { FunctionComponent } from 'react';

import { IconProps } from './interfaces';
import { StyledIconContainer } from './Styles';

const Icon: FunctionComponent<IconProps> = ({ Icon: Component, isSpinning, ...rest }) => {
  return (
    <StyledIconContainer spinning={isSpinning}>
      <Component {...rest} />
    </StyledIconContainer>
  );
};

export default Icon;
