import { css, keyframes } from 'styled-components';

const spinningKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const spinningAnimation = css`
  animation: ${spinningKeyframes} 1s linear infinite;
`;
