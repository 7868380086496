import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import AppRouter from 'routes/AppRouter';

import GlobalStyles from 'styles/GlobalStyles';
import theme from 'styles/theme';

import { PAYPAL_CLIENT_ID } from 'utils/constants/env';

const App: FunctionComponent = () => {
  if (!PAYPAL_CLIENT_ID) {
    console.error('No PayPal client ID is set in .env file');
    return null;
  }

  return (
    <PayPalScriptProvider options={{ 'client-id': PAYPAL_CLIENT_ID, currency: 'EUR' }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppRouter />
      </ThemeProvider>
    </PayPalScriptProvider>
  );
};

export default App;
