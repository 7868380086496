import styled from 'styled-components';

export const StyledCodeBlock = styled.pre`
  background-color: ${({ theme }): string => theme.colors.gray}11;
  padding: ${({ theme }): string => theme.spacing.s};
  word-wrap: break-word;
  white-space: pre-wrap;

  code {
    color: ${({ theme }): string => theme.colors.error};
    word-wrap: break-word;
  }
`;
