import React, { FunctionComponent } from 'react';

import logo from 'assets/images/logo.png';

import { Image } from 'components/atoms/Image';

const Logo: FunctionComponent = () => {
  return <Image src={logo} alt="Optifarm" />;
};

export default Logo;
