/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { queryParamTypes } from 'utils/enums/queryParams';
import { Routes } from 'utils/enums/routes';
import { IDispatcherValidation } from 'utils/interfaces/dispatcher';
import { amountQueryParam, osQueryParam, providerQueryParam, redirectUriQueryParam } from 'utils/types/queryParams';

import { areAllValuesTruthy } from './composites/bools';
import { isValidOs } from './os';
import { isValidProvider } from './providers';
import { getRedirectPathByProvider } from './routing';

/**
 * Returns all query parameters passed to the current
 * window.location.href
 * @function
 * @param {string} param
 * @return {string|null} Query Parameter or null
 */
export const getQueryParameter = (param: string): string | null => {
  const queryParameters = new URLSearchParams(window.location.search);
  return queryParameters.get(param) || null;
};

/**
 * Returns value of amount query param
 * @function
 * @param {URLSearchParams} params
 * @returns {amountQueryParam|null} Amount query param value
 */
export const getAmountQueryParam = (params: URLSearchParams): amountQueryParam | null => {
  return params.get(queryParamTypes.AMOUNT);
};

/**
 * Returns value of os query param
 * @function
 * @param {URLSearchParams} params
 * @returns {osQueryParam|null} OS query param value
 */
export const getOsQueryParam = (params: URLSearchParams): osQueryParam | null => {
  const param = params.get(queryParamTypes.OS);

  if (param) {
    return param as osQueryParam;
  }

  return null;
};

/**
 * Returns value of provider query param
 * @function
 * @param {URLSearchParams} params
 * @returns {osQueryParam|null} Provider query param value
 */
export const getProviderQueryParam = (params: URLSearchParams): providerQueryParam | null => {
  const param = params.get(queryParamTypes.PROVIDER);

  if (param) {
    return param as providerQueryParam;
  }

  return null;
};

/**
 * Returns value of return_uri query param
 * @function
 * @param {URLSearchParams} params
 * @returns {redirectUriQueryParam|null} Provider query param value
 */
export const getRedirectUriQueryParam = (params: URLSearchParams): redirectUriQueryParam | null => {
  const param = params.get(queryParamTypes.REDIRECT_URI);

  if (param) {
    return param as redirectUriQueryParam;
  }

  return null;
};

/**
 * Returns dispatch validation object containing boolean valid, redirectPath string
 * and redirectState object
 * @function
 * @param {array} queryParams
 * @returns {IDispatcherValidation}  Dispatch validation object
 */
export const getQueryParamsConfig = ({ amount, provider, redirectUri, os }: any): IDispatcherValidation => {
  if (!areAllValuesTruthy([amount, provider, redirectUri, os])) {
    return {
      valid: false,
      redirectPath: Routes.ERROR,
      redirectState: { error: 'Invalid params were provided...', params: { amount, provider, redirectUri, os } }
    };
  }

  if (!isValidProvider(provider)) {
    return {
      valid: false,
      redirectPath: Routes.ERROR,
      redirectState: { error: `${provider} is not a valid provider...`, params: { amount, provider, redirectUri, os } }
    };
  }

  if (!isValidOs(os)) {
    return {
      valid: false,
      redirectPath: Routes.ERROR,
      redirectState: { error: `${os} is not a valid os...`, params: { amount, provider, redirectUri, os } }
    };
  }

  if (!Number.isInteger(parseInt(amount))) {
    return {
      valid: false,
      redirectPath: Routes.ERROR,
      redirectState: {
        error: `${amount} is not a parsable integer and therefore not a valid amount parameter...`,
        params: { amount, provider, redirectUri, os }
      }
    };
  }

  return {
    valid: true,
    redirectPath: getRedirectPathByProvider(provider),
    redirectState: { amount, os, redirectUri }
  };
};

/**
 * Returns an object with key value pairs of all passed query params
 * @function
 * @param {URLSearchParams} queryParams
 * @returns {object} Query Params object
 */
export const getQueryParamsObject = (queryParams: URLSearchParams): Record<string, unknown> => {
  const paramsObject: Record<string, unknown> = {};

  queryParams.forEach((param, key) => {
    paramsObject[key] = param;
  });

  return paramsObject;
};
