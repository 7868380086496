import styled from 'styled-components';

export const StyledPayPalButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${({ theme }): string => theme.spacing.m};
  position: relative;
  z-index: 99;
`;
