/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/**
 * Returns true if provided value is truthy
 * @function
 * @param {*} value
 * @return {boolean} boolean
 */
export const isTruthy = (value: any): boolean => {
  return !!value;
};

/**
 * Returns true if provided value is falsy
 * @function
 * @param {*} value
 * @return {boolean} boolean
 */
export const isFalsy = (value: any): boolean => {
  return !value;
};

/**
 * Returns true if values are equal
 * @function
 * @param {*} a
 * @param {*} b
 * @return {boolean} boolean
 */
export const isEqual = (a: any, b: any): boolean => {
  return a === b;
};
