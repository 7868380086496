import React, { FunctionComponent } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { Icon } from 'components/atoms/Icon';

import { LoadingIndicatorProps } from './interfaces';
import { StyledLoadingIndicator } from './Styles';

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = ({ size = 42 }) => {
  return (
    <StyledLoadingIndicator>
      <Icon Icon={AiOutlineLoading3Quarters} isSpinning={true} size={size} />
    </StyledLoadingIndicator>
  );
};

export default LoadingIndicator;
