import React, { FunctionComponent } from 'react';

import { ImageProps } from './interfaces';
import { StyledImage } from './Styles';

const Image: FunctionComponent<ImageProps> = (props) => {
  return <StyledImage {...props} />;
};

export default Image;
