import { CreateOptions } from '@paypal/react-paypal-js';

import { RedirectType } from 'utils/enums/redirercts';

/**
 * Returns prepared object for creating a new order on PayPal
 * @function
 * @param {string} amount
 * @returns {CreateOptions} Create Order Data
 */
export const createPayPalOrderData = (amount: string | null): CreateOptions => {
  if (!amount) {
    throw new Error('Amount parameter is need in order to create PayPal data object');
  }

  if (!Number.isInteger(parseInt(amount))) {
    throw new Error('Amount should be a parsable number string (parseInt(amount) should yield in a number).');
  }

  return {
    application_context: {
      brand_name: 'Optifarm Smart',
      locale: 'en',
      landing_page: 'NO_PREFERENCE',
      shipping_preference: 'GET_FROM_FILE',
      user_action: 'PAY_NOW'
    },
    intent: 'CAPTURE',
    purchase_units: [
      {
        amount: {
          currency_code: 'EUR',
          value: `${amount}`
        }
      }
    ]
  };
};

/**
 * Returns formatted success redirect uri with added order and payer ID
 * @function
 * @param {object} RedirectUriOptions
 * @returns {string} Redirect uri string
 */
export const getSuccessRedirectUri = ({
  redirectUri,
  payerID,
  orderID
}: {
  redirectUri: string;
  payerID: string;
  orderID: string;
}): string => {
  const url = new URL(redirectUri);
  url.searchParams.append('type', RedirectType.SUCCESS);
  url.searchParams.append('orderID', orderID);
  url.searchParams.append('payerID', payerID);

  return url.toString();
};

/**
 * Returns formatted cancel redirect uri with added order ID
 * @function
 * @param {object} RedirectUriOptions
 * @returns {string} Redirect uri string
 */
export const getCancelRedirectUri = ({ redirectUri, orderID }: { redirectUri: string; orderID: string }): string => {
  const url = new URL(redirectUri);
  url.searchParams.append('type', RedirectType.CANCEL);
  url.searchParams.append('orderID', orderID);

  return url.toString();
};

/**
 * Returns formatted error redirect uri
 * @function
 * @param {object} RedirectUriOptions
 * @returns {string} Redirect uri string
 */
export const getErrorRedirectUri = ({ redirectUri }: { redirectUri: string }): string => {
  const url = new URL(redirectUri);
  url.searchParams.append('type', RedirectType.ERROR);

  return url.toString();
};
