import styled from 'styled-components';

import { spinningAnimation } from 'styles/animations';

export const StyledIconContainer = styled.span<{ spinning: boolean }>`
  svg {
    color: ${({ theme }): string => theme.colors.primary};
    ${({ spinning }): any => spinning && spinningAnimation}
  }
`;
